<div class="section-card">
  <div class="header h70">
    <div class="title">
      <span>{{ "PS_FORM.StrategyDescription" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="col-9">
        <form [formGroup]="oldStrategyForm">
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label for="projectStartDate">
                {{ "PS_FORM.ProjectStartDate" | translate }}
              </mat-label>
              <input
                matInput
                [matDatepicker]="projectStartDate"
                formControlName="projectStartDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="projectStartDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #projectStartDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label for="projectStrategyStart">
                {{ "PS_FORM.ProjectStrategyStart" | translate }}
              </mat-label>
              <input
                matInput
                [matDatepicker]="projectStrategyStart"
                formControlName="projectStrategyStart"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="projectStrategyStart"
              ></mat-datepicker-toggle>
              <mat-datepicker #projectStrategyStart></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label for="projectEstimatedStrategyEnd">
                {{ "PS_FORM.ProjectEstimatedStrategyEnd" | translate }}
              </mat-label>
              <input
                matInput
                [matDatepicker]="projectEstimatedStrategyEnd"
                formControlName="projectEstimatedStrategyEnd"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="projectEstimatedStrategyEnd"
              ></mat-datepicker-toggle>
              <mat-datepicker #projectEstimatedStrategyEnd></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label for="expectedMonths">
                {{ "PS_FORM.ExpectedMonths" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                [value]="getExpectedMonthsDifference()"
                name="expectedMonths"
                disabled
              />
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label for="monthsFromStrategyStart">
                {{ "PS_FORM.MonthsActuallyElapsed" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                [value]="getActuallyMonthsDifference()"
                name="monthsFromStrategyStart"
                disabled
              />
            </mat-form-field>
            <!-- <mat-form-field class="col-4">
              <mat-label for="monthFromProjectStart">
                {{ "PS_FORM.MonthFromProjectStart" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                formControlName="monthFromProjectStart"
                name="monthFromProjectStart"
              />
            </mat-form-field> -->
            <mat-form-field class="col-4">
              <mat-label for="estimatedRevenue">
                {{ "PS_FORM.EstimatedRevenue" | translate }}
              </mat-label>
              <input
                currencyMask
                matInput
                formControlName="estimatedRevenue"
                [options]="currencyDefaultConfig"
                name="estimatedRevenue"
              />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label for="effectiveRevenue">
                {{ "PS_FORM.EffectiveRevenue" | translate }}
              </mat-label>
              <input
                currencyMask
                matInput
                formControlName="effectiveRevenue"
                [options]="currencyDefaultConfig"
                name="effectiveRevenue"
              />
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label for="deviationRevenue">
                {{ "PS_FORM.DeviationRevenue" | translate }}
              </mat-label>
              <input
                currencyMask
                matInput
                formControlName="deviationRevenue"
                [options]="currencyDefaultConfigAllowNegative"
                name="deviationRevenue"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-3">
        <div class="trend-class-container">
          <mat-icon
            [ngClass]="
              handleTrendClass(oldStrategyForm.get('deviationRevenue').value)
            "
          >
            {{ handleTrend(oldStrategyForm.get("deviationRevenue").value) }}
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="activeStrategyForm">
  <div class="section-card mt-30">
    <div class="header h70">
      <div class="title">
        <span>{{ "PS_FORM.ClosingParametersCurrentPS" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field formGroupName="projectObjective" class="col-4">
          <mat-label for="productName">
            {{ "PS_FORM.Product" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="productName"
            [readonly]="true"
            name="productName"
          />
        </mat-form-field>
        <mat-form-field
          class="col-8"
          [ngClass]="
            !activeStrategyForm.get('idsMarketsSelected')?.value && dataIsReady
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="idsMarketsSelected">
            {{ "PS_FORM.Markets" | translate }} ({{
              "PS_FORM.SelectTheMarketsToInsertInTheRAP" | translate
            }})
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            #activeMarketStrategySelect
            formControlName="idsMarketsSelected"
            name="idsMarketsSelected"
            multiple
          >
            <mat-option
              #activeMarketStrategyOption
              *ngFor="
                let market of activeMarketProjectObjective.value;
                let i = index
              "
              [value]="market.idCountry"
              (click)="
                handleActiveMarketStrategy(activeMarketStrategyOption, i)
              "
            >
              {{ market.country }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="activeStrategyForm.get('idsMarketsSelected').invalid"
          >
            {{
              activeStrategyForm.get("idsMarketsSelected").errors
                | getErrorMessage
                  : translations.ActiveStrategyDescription
                  : ""
                  : true
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-12 mt-10"
          [ngClass]="
            !activeStrategyForm.get('idsChannelsSelected')?.value && dataIsReady
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="idsChannelsSelected">
            {{ "PS_FORM.Channels" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            #activeChannelStrategySelect
            formControlName="idsChannelsSelected"
            name="idsChannelsSelected"
            multiple
          >
            <mat-option
              [disabled]="true"
              #activeChannelStrategyOption
              *ngFor="let channel of activeChannelProjectObjective.value"
              [value]="channel.idChannelType"
            >
              {{ channel.channelSpecification }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<div *ngIf="!isIspDelivered && !isReadOnly" class="center-buttons mt-50">
  <button
    type="button"
    class="rounded-button primary-bg-button"
    (click)="saveCurrentStrategy()"
  >
    {{ "COMMON.SaveChanges" | translate }}
  </button>
</div>
