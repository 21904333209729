import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import Config from 'src/app/config';
import { IAppointment } from 'src/app/models/appointment';
import { IManagerStatistics } from 'src/app/models/dashboard';
import { ServiceExtension } from 'src/app/services/serviceExtension';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ServiceExtension {
  constructor(private http: HttpClient) {
    super();
  }

  getAppointmentStats(
    startDate: string,
    endDate: string,
    idAppointmentState: number = 1,
    idUserAgent?: number
  ): Observable<any> {
    let par: Params = new HttpParams()
      .set('from', startDate)
      .set('to', endDate)
      .set('idAppointmentState', idAppointmentState.toString());
    if (idUserAgent) {
      par = par.append('idUserAgent', idUserAgent);
    }
    return this.http
      .get<any>(`${Config.apiRootUrl}/appointment/stats`, {
        params: par,
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getSupervisorAppointments(
    startDate: string,
    endDate: string,
    idAppointmentState: number = 1,
    idUserAgent?: number,
    limit?: number
  ): Observable<IAppointment[]> {
    let par: Params = new HttpParams()
      .set('from', startDate)
      .set('to', endDate)
      .set('idAppointmentState', idAppointmentState.toString());
    if (idUserAgent) {
      par = par.append('idUserAgent', idUserAgent);
    }
    if (limit) {
      par = par.append('limit', limit);
    }
    return this.http
      .get<IAppointment[]>(`${Config.apiRootUrl}/appointment/`, {
        params: par,
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getManagerStatistics(): Observable<IManagerStatistics> {
    return this.http
      .get<IManagerStatistics>(
        `${Config.apiRootUrl}/dashboard/statistics/period/manager`
      )
      .pipe();
  }

  getDLManagerDashboardStatistics(
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/dashboard/statistics/period/manager`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getDLManagerSupervisorDashboardStatistics(): Observable<any> {
    return this.http
      .get(
        `${Config.apiRootUrl}/dashboard/statistics/period/manager/supervisor`
      )
      .pipe();
  }

  getDirectionDashboardStatistics(
    dateFrom: string,
    dateTo: string,
    typeOutput: number
  ): Observable<any> {
    const par: Params = new HttpParams()
      .set('dateFrom', dateFrom)
      .set('dateTo', dateTo)
      .set('typeOutput', typeOutput.toString());
    return this.http
      .get<any>(`${Config.apiRootUrl}/dashboard/statistics/period/direction`, {
        params: par,
        headers: this._getHeaders(true),
      })
      .pipe();
  }
}
