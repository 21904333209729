import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSelect',
})
export class FilterSelectPipe implements PipeTransform {
  transform(list: any[], filter: any, key?: any): any {
    if (!list || !filter) {
      return list;
    }
    return list.filter((item: any) => {
      if (key) {
        if (typeof filter === 'number') {
          return item[key].toString().indexOf(filter.toString()) !== -1;
        } else {
          return item[key].toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        }
      } else {
        if (typeof filter === 'number') {
          return item.toString().indexOf(filter.toString()) !== -1;
        } else {
          return item.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        }
      }
    });
  }
}
