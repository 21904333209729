import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNotDynamicTable',
})
export class FilterNotDynamicTablePipe implements PipeTransform {
  transform(items: any[], searchValue: string): unknown {
    if (!items || !searchValue) {
      return items;
    }
    const result: any[] = [];
    items.forEach((item: any) => {
      Object.keys(item).forEach((key: string) => {
        if (typeof item[key] === 'string') {
          if (item[key]?.toLowerCase().includes(searchValue.toLowerCase())) {
            if (!result.includes(item)) {
              result.push(item);
            }
          }
        }
      });
    });
    return result;
  }
}
