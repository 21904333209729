<div class="header">
  <div class="title">
    {{ "IR_FORM.AddressCoordinates" | translate }}
  </div>
  <button class="close-dialog" mat-dialog-close mat-icon-button>
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div class="content">
  <form [formGroup]="addressPositionForm">
    <div class="row">
      <mat-form-field appearance="standard" class="col-6">
        <mat-label for="longitude">
          {{ "COMMON.Longitude" | translate }}
        </mat-label>
        <input
          matInput
          type="number"
          formControlName="longitude"
          name="longitude"
          (focusout)="checkAndSetCoordinates()"
        />
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-6">
        <mat-label for="latitude">
          {{ "COMMON.Latitude" | translate }}
        </mat-label>
        <input
          matInput
          type="number"
          formControlName="latitude"
          name="latitude"
          (focusout)="checkAndSetCoordinates()"
        />
      </mat-form-field>
    </div>
    <div
      #mapDiv
      class="mt-30 map-container p-relative"
      style="height: 300px; width: 100%"
    ></div>
  </form>
</div>
<div class="buttons center">
  <button
    type="button"
    (click)="savePosition()"
    class="rounded-button primary-bg-button"
  >
    {{ "COMMON.Confirm" | translate }}
  </button>
</div>
