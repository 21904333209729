import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSelectByCondition',
})
export class FilterSelectByConditionPipe implements PipeTransform {
  transform(list: any[], filter: any, key?: any): any {
    if (!list || !filter) {
      return list;
    }
    return list.filter((item: any) => {
      if (key) {
        if (typeof filter === 'number') {
          return item[key] === filter;
        } else {
          return item[key].toLowerCase() === filter;
        }
      } else {
        if (typeof filter === 'number') {
          return item === filter;
        } else {
          return item === filter;
        }
      }
    });
  }
}
